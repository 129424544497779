import React from 'react';
import data from '../products.json';
import { Helmet } from 'react-helmet';
import NotFound from './NotFound';
import misc from '../misc.json';
import { generateFullTitle } from '../helpers';
import { Image } from './Image';

let item = []

const search = (props) => {
    for (var i=0; i < data.length; i++) {
        if (data[i].title === props.match.params.id) {
            item = data[i];
        }
    }
}

const Product = props => {
    search(props);

    if(item.length <= 0){
        return (<div><NotFound /></div>);
    }

    let final_images = [];
    let item_image_obj = item['image']

    if (typeof(item['image']) != 'object') {
        item_image_obj = [item['image']]
    }

    item_image_obj.forEach((path) => {
        final_images.push(
            <Image
                className="portfolioitem-img"
                src={process.env.PUBLIC_URL + '/uploads/products/' + path}
                alt={item['title']}
                key={item['title'] + path}
            />
        )
    })

    return (
        <div>
            <Helmet>
                <title>{item['title']} - Տպարան․am</title>
                <meta name="description" content={misc['meta']['description'] + " Տպարան․am - " + item['title']}/>
                <meta name="keywords" content={misc['meta']['keywords']}/>
            </Helmet>
            <div className="header"> {/*  style={{height: '120px'}} */}
                <div className="fh5co-narrow-content">
                    <h2 style={{textAlign: 'center', color: 'white'}} >{generateFullTitle(item)}</h2>
                </div>
            </div>
            <div className="item-container">
                <div className="item-image">
                    {final_images}
                </div>

                <div className="item-desc">
                    {item['content']}
                    <br /><br />
                    Ձեր հարցումներն ուղարկեք <a href="mailto:info@tparan.am">info@tparan.am</a> էլ.հասցեին:<br />
                    Ավելի մանրամասն տեղեկատվության համար զանգահարեք <a href="tel:+37460446685">060446685</a> կամ <a href="tel:+37493407038">093407038</a> հեռախոսահամարներով՝ մենք սիրով  կպատասխանենք Ձեր բոլոր հարցերին:
                </div>

                {/* <p><small><a href="Http://tparan.am">Տպարան.am</a>-ի կողմից կատարվում է անհատական հաշվարկ յուրաքանչյուր
				հաճախորդի համար: Ստորև ներկայացված են միջին շուկայական գները, որոնք հաշվարկված են մեկ օրինակի համար և
				չեն ներառում ԱԱՀ:</small></p> */}
            </div>
        </div>
    );
};

export default Product;
